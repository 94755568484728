
  .landing-page-container {
    background-color: white;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    height: 100vh;
  }
  .landing-page-header {
    font-size: 2.93rem;
    margin: 0;
  }

  .landing-page-subheader {
    font-size: 1.5rem;
    transform: translateX(215px);
    margin: 0;
    margin-bottom: 2rem;
  }

  .social-media-icons-container {
    margin-top: 0rem;
  }

  .social-media-icon-link {
    margin: 0 0.5rem;
  }

  .projects-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: 100%;
  }

  .project {
    background-color: #f2f2f2;
    border-radius: 10px;
    padding-left: 1rem;
    width: 100%;
    max-width: 315px;
  }

  .project-title {
    font-size: 1.5rem;
    margin-bottom: 5px;
  }

  .project-description {
    font-size: 0.75rem;
    margin-bottom: 5px;
  }

  .project-link {
    font-size: 0.75rem;
    margin-bottom: 1rem;
  }

  .landing-page-footer {
    height: 1rem;
  }

  .footer-text {
    font-size: 10px;
  }

